import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";

export const useSidebarData = () => {
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    setRole(storedRole);
    setPermissions(permissions);
  }, []);
  console.log(role)
  return [

// User Routes
    role === "user" && {
      label: "User Management",
      submenuOpen: true,
      submenuHdr: "User Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        permissions.includes('user_managment') && {
          label: "User Management",
          link: "/admin-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        permissions.includes('controller_managment') && {
          label: "Controller Management",
          link: "/controller-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        permissions.includes('agent_managment') && {
          label: "Agent Management",
          link: "/agent-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "User Account",
          link: "/user-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ].filter(Boolean), // This removes any false or undefined elements from the array
    },
    role == "user" && permissions.includes('region_managment') && {
      label: "Region Management",
      submenuOpen: true,
      submenuHdr: "Region Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Region Management",
          link: "/region-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('district_managment') && {
      label: "District Management",
      submenuOpen: true,
      submenuHdr: "District Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "District Management",
          link: "/district-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('consistuency_managment') && {
      label: "Constituency Management",
      submenuOpen: true,
      submenuHdr: "Constituency Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Constituency Management",
          link: "/consistuency-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('pollingstation_managment') && {
      label: "Poll Management",
      submenuOpen: true,
      submenuHdr: "Poll Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Polling Stations",
          link: "/polling-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('parties_managment') && {
      label: "Party Management",
      submenuOpen: true,
      submenuHdr: "Party Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Party Managment",
          link: "/party-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('candidate_managment') && {
      label: "Candidate Management",
      submenuOpen: true,
      submenuHdr: "Candidate Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Candidate Management",
          link: "/candidate-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "user" && permissions.includes('result_managment') && {
      label: "Results Input & Report",
      submenuOpen: true,
      submenuHdr: "Results Input & Report",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Results Input & Report",
          link: "/results",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },

    //SUPER ADMIN ROUTES
    ( role == "admin") && {
      label: "Main",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      
      submenuItems: [
        {
          label: "Dashboard",
          icon: <Icon.Grid />,
          submenu: true,
          showSubRoute: false,
          link:"/admin-dashboard",
          // submenuItems: [
          //   { label: "Main Dashboard", link: "/" },
          // ],
        },
      ],
    },
    (role == "super admin") && {
      label: "Main",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      
      submenuItems: [
        {
          label: "Dashboard",
          icon: <Icon.Grid />,
          submenu: true,
          showSubRoute: false,
          link:"/",
          // submenuItems: [
          //   { label: "Main Dashboard", link: "/" },
          // ],
        },
      ],
    },
    (role == "super admin" || role == "admin") && {
      label: "User Management",
      submenuOpen: true,
      submenuHdr: "User Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       role == "super admin" && {
          label: "User Management",
          link: "/admin-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        role == "super admin" &&{
          label: "Admin Managment",
          link: "/user-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
       role == "super admin" && {
          label: "Controller Management",
          link: "/controller-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        role == "super admin" && {
          label: "Agent Management",
          link: "/agent-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
       role == "admin" && {
          label: "Controller Management",
          link: "/admin-controller-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      
       role == "admin" && {
          label: "Agent Management",
          link: "/admin-agent-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
    
      ].filter(Boolean),
    },
    (role == "super admin" || role == "admin") && {
      label: "News Management",
      submenuOpen: true,
      submenuHdr: "News Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       {
          label: "News Management",
          link: "/news-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
     
      
      ].filter(Boolean),
    },
    (role == "super admin" || role == "admin") && {
      label: "Region Management",
      submenuOpen: true,
      submenuHdr: "Region Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Region Management",
          link: "/region-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },

    (role == "super admin" || role == "admin") && {
      label: "District Management",
      submenuOpen: true,
      submenuHdr: "District Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "District Management",
          link: "/district-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },

    (role == "super admin" || role == "admin") && {
      label: "Constituency Management",
      submenuOpen: true,
      submenuHdr: "Constituency Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Constituency Management",
          link: "/consistuency-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },



    (role == "super admin" || role == "admin") && {
      label: "Poll Management",
      submenuOpen: true,
      submenuHdr: "Poll Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Polling Stations",
          link: "/polling-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin") && {
      label: "Party Management",
      submenuOpen: true,
      submenuHdr: "Party Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Party  Managment",
          link: "/party-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin" ) && {
      label: "Candidate Management",
      submenuOpen: true,
      submenuHdr: "Candidate Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Candidate Management",
          link: "/candidate-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin") && {
      label: "Results & Analytics",
      submenuOpen: true,
      submenuHdr: "Results & Analytics",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       
        {
          label: "Results Input",
          link: "/results",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Results Display",
          link: "/result-data",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Past Results",
          link: "/records",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "admin") && {
      label: "Results & Analytics",
      submenuOpen: true,
      submenuHdr: "Results & Analytics",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       
        {
          label: "Results Input",
          link: "/results",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Results Display",
          link: "/admin-result-display",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Past Results",
          link: "/records",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin") && {
      label: "Reporting",
      submenuOpen: true,
      submenuHdr: "Reporting",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       
        {
          label: "National Reports",
          link: "/national-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Regional Reports",
          link: "/region-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "District Reports",
          link: "/district-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Constituency Reports",
          link: "/constituency-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Polling Station Reports",
          link: "/pollingstation-reports",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Past Reports",
          link: "/past-records",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        }
      ],
    },
    (role == "admin") && {
      label: "Reporting",
      submenuOpen: true,
      submenuHdr: "Reporting",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
       
        {
          label: "National Reports",
          link: "/admin-national-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Regional Reports",
          link: "/admin-region-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "District Reports",
          link: "/admin-district-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Constituency Reports",
          link: "/admin-constituency-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Polling Station Reports",
          link: "/admin-pollingstation-reports",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Past Reports",
          link: "/past-records",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        }
      ],
    },
  
    (role == "super admin" ) && {
      label: "Designations",
      submenuOpen: true,
      submenuHdr: "Designations",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Designations",
          link: "/designations",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin" || role == "admin") && {
      label: "e-Ballot Management",
      submenuOpen: true,
      submenuHdr: "e-Ballot Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "e-Ballot Management",
          link: "/ballot-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "super admin") && {
      label: "Front Site Setting",
      submenuOpen: true,
      submenuHdr: "Front Site Setting",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Front Site Setting",
          link: "/constituency-selection",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },


    (role === "controller" || role === "member") && {
      label: "Main",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      submenuItems: [
        {
          label: "Controller Dashboard",
          icon: <Icon.Grid />,
          submenu: true,
          showSubRoute: false,
          link: "/controller-dashboard"
        },
      ],
    },
    (role === "controller" || role === "member") && {
      label: "Agent Management",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      submenuItems: [
        {
          label: "Agent Management",
          icon: <Icon.Grid />,
          submenu: true,
          showSubRoute: false,
          link: "/controller-agent-managment"
        },
      ],
    },
    (role === "controller" || role === "member") && {
      label: "Region Management",
      submenuOpen: true,
      submenuHdr: "Region Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Region Management",
          link: "/controller-region-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role === "controller") && {
      label: "Team Management",
      submenuOpen: true,
      submenuHdr: "Team Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Team Management",
          link: "/team-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role === "controller" || role === "member") && {
      label: "District Management",
      submenuOpen: true,
      submenuHdr: "District Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "District Management",
          link: "/controller-district-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role === "controller" || role === "member") && {
      label: "Constituency Management",
      submenuOpen: true,
      submenuHdr: "Constituency Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Constituency Management",
          link: "/controller-consistuency-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role === "controller" || role === "member") && {
      label: "Poll Management",
      submenuOpen: true,
      submenuHdr: "Poll Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Polling Stations",
          link: "/controller-polling-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    (role == "controller") && {
      label: "Reporting",
      submenuOpen: true,
      submenuHdr: "Reporting",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
     
        {
          label: "Constituency Reports",
          link: "/controller-constituency-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Polling Station Reports",
          link: "/controller-pollingstation-report",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Past Reports",
          link: "/past-records",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        }
      ],
    },
    (role === "controller" || role === "member") && {
      label: "Results & Analytics",
      submenuOpen: true,
      submenuHdr: "Results & Analytics",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Results Input",
          link: "/controller-results",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Results Display",
          link: "/controller-result-display",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    

  
    (role == "admin") && {
      label: "Party Management",
      submenuOpen: true,
      submenuHdr: "Party Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Party  Managment",
          link: "/admin-party-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },

   

   
    (role == "admin" ) && {
      label: "Candidate Management",
      submenuOpen: true,
      submenuHdr: "Candidate Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Candidate Management",
          link: "/admin-candidate-managment",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
   
  
    (role == "super admin" ) && {
      label: "Sessions",
      submenuOpen: true,
      submenuHdr: "Sessions",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Sessions",
          link: "/sessions",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },


    role == "agent" && {
      label: "Main",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      submenuItems: [
        {
          label: "Agent Dashboard",
          icon: <Icon.Grid />,
          submenu: true,
          showSubRoute: false,
          link: "/agent-dashboard"
          // submenuItems: [
          //   { label: "Agent Dashboard", link: "/agent-dashboard" },
          // ],
        },
      ],
    },



    //agent routes
    role == "agent" && {
      label: "Poll Management",
      submenuOpen: true,
      submenuHdr: "Poll Management",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Polling Stations",
          link: "/agent-pollingstations",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
    role == "agent" && {
      label: "Results Input & Report",
      submenuOpen: true,
      submenuHdr: "Results Input & Report",
      submenu: true,
      showSubRoute: false,
      submenuItems: [
        {
          label: "Results Inputs",
          link: "/agent-result",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
        {
          label: "Results Display",
          link: "/results",
          icon: <Icon.Package />,
          showSubRoute: false,
          submenu: false,
        },
      ],
    },
  


  ].filter(Boolean); // Filter out any null or false values
};
