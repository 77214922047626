import React, { useEffect, useState, useRef } from "react";
import { Search, Edit, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { ToastContainer } from "react-toastify";
import formatDate from "../../Utils/DateFormat";

const CandidateManagement = () => {
    const [userData, setUserData] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [party, setParty] = useState("");
    const [type, setType] = useState("");
    const [year, setYear] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [consistuency, setConsistuency] = useState("");
    const [candidateType, setCandidateType] = useState("");
    const fileInputRef = useRef(null);
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const { apiData, reFetch } = useFetch("getAllCandidates");

    const parties = useFetch("getAllParties");
    console.log(parties)
    const types = useFetch("getAllCandidateTypes");
    const constituencies = useFetch("getAllConsistuencies");

    useEffect(() => {
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')));
        if (apiData?.data?.candidates) {
            const transformedData = apiData.data.candidates.map((candidate) => ({

                id: candidate?.candidate?.id,
                name: candidate?.candidate?.name,
                constName: candidate?.consistuency ? candidate?.consistuency?.name : "No Data",
                constId: candidate?.consistuency?.id,
                party: candidate?.candidate?.party?.name,
                partyId: candidate?.candidate?.party?.id,
                type: candidate?.candidate?.candidatetype?.name,
                typeId: candidate?.candidate?.candidatetype?.id,
                year: formatDate(candidate.candidate?.createdAt),
            }));
            setUserData(transformedData);
            setFilteredData(transformedData);
        }
        if (role === "admin") {
            setParty(localStorage.getItem("partyId"));
        }
    }, [apiData, role, permissions]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setName("");
        setParty("");
        setType("");
        setConsistuency("");

        setYear(new Date().getFullYear());
        setShow(true);
    };

    const addCandidate = async () => {
        setLoading(true);
        const response = await PostApi("addCandidate", {
            name,
            party,
            type,
            consistuency
        });
        if (response.data.status === "1") {
            reFetch();
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
        }
        setLoading(false);
    };

    const deleteUser = async (id) => {
        const response = await PostApi("deleteCandidate", { id });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
        } else {
            error_toaster(response.data.message);
        }
    };

    const editUser = (obj) => {
        setEdit(true);
        setName(obj.name);
        setParty(obj.partyId);
        setYear(obj.year);
        setType(obj.typeId);
        setCandidateType(obj.type);
        setConsistuency(obj.constId);
        setId(obj.id);
        setShow(true);
    };

    const updateUser = async () => {
        setLoading(true);
        const response = await PostApi("updateCandidate", {
            id,
            name,
            party,
            type,
            year,
            consistuency
        });
        if (response.data.status === "1") {
            reFetch();
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
        }
        setLoading(false);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("addCandidatesFromExcel", formData, true);
        if (response.data.status === "1") {
            reFetch();
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
        }
        setLoading(false);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const changeCandidateType = async (e) => {
        try {
            const response = await PostApi(`candidateType`, { typeId: e });
            if (response.data.status === "1") {
                setCandidateType(response.data.data.type.name);
                setType(e);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster(error.message);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.party.length - b.party.length,
            render: (party) => <span className="btn btn-warning btn-sm" style={{ width: "90px" }}>{party}</span>,
        },
        {
            title: "Candidate Type",
            dataIndex: "type",
            render: (type) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{type}</span>,
        },
        {
            title: "Constituency",
            dataIndex: "constName",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {role === "super admin" && (
                            <>
                                <Link className="me-2 p-2" to="#" onClick={() => editUser(record)}>
                                    <Edit className="feather-edit" />
                                </Link>
                                <Link className="confirm-text p-2" to="#" onClick={() => deleteUser(record.id)}>
                                    <Trash2 className="feather-trash-2" />
                                </Link>
                            </>
                        )}
                        {role === "user" && (
                            <>
                                {permissions.includes("edit_candidate") && (
                                    <Link className="me-2 p-2" to="#" onClick={() => editUser(record)}>
                                        <Edit className="feather-edit" />
                                    </Link>
                                )}
                                {permissions.includes("delete_candidate") && (
                                    <Link className="confirm-text p-2" to="#" onClick={() => deleteUser(record.id)}>
                                        <Trash2 className="feather-trash-2" />
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ),
        },
    ];
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterData(query);
        console.log(query)
    };
    const filterData = (query) => {
        const filteredRecords = userData.filter((record) => {
            const matchesQuery = record.name.toLowerCase().match(query);
            return matchesQuery;
        });
        setFilteredData(filteredRecords);
    };
    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <ToastContainer />
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Candidate Management</h4>
                                <h6>Manage Candidates</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            {(role === "admin" || role === "super admin") && (
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                        <button className="btn btn-link" onClick={handleUploadClick}>
                                            <Upload />
                                        </button>
                                    </OverlayTrigger>
                                </li>
                            )}
                        </ul>
                        <div className="page-btn">
                            {(role === "admin" || role === "super admin") && (
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            )}
                            {role === "user" && permissions.includes('add_candidate') && (
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: userData.length }} />
                        </div>
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{edit ? "Update Candidate" : "Add Candidate"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name"
                                    />
                                </div>
                                {role === "super admin" && (
                                    <div className="form-group">
                                        <label>Party</label>
                                        <select className="form-control" value={party} onChange={(e) => setParty(e.target.value)}>
                                            <option value="">Select Party</option>
                                            {parties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>{party.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                <div className="form-group">
                                    <label>Candidate Type</label>
                                    <select defaultValue={type} onChange={(e) => changeCandidateType(e.target.value)} value={type} className="form-control">
                                        <option value="">Select Type</option>
                                        {types?.apiData?.data?.types?.map(dd => (
                                            <option key={dd.id} value={dd.id}>{dd.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {candidateType === "Parliamentary" && (
                                    <div className="form-group">
                                        <label>Constituency</label>
                                        <select onChange={(e) => setConsistuency(e.target.value)} value={consistuency} className="form-control">
                                            <option value="">Select Constituency</option>
                                            {constituencies?.apiData?.data?.consistuencies?.map((dd) => (
                                                <option key={dd.id} value={dd.id}>{dd.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                <div className="d-flex justify-content-center gap-3 mt-3">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Close
                                    </button>
                                    {edit ? (
                                        <button onClick={updateUser} type="submit" className="btn btn-primary">
                                            Update
                                        </button>
                                    ) : (
                                        <button onClick={addCandidate} type="submit" className="btn btn-primary">
                                            Save
                                        </button>
                                    )}
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default CandidateManagement;