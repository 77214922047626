import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Table from "../../core/pagination/datatable";
import { IMAGE_URL } from "../../Utils/urls";
import formatDate from "../../Utils/DateFormat";

const NationalReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);
    const [pastResult, setPastResult] = useState([]);
    const [pastRecords, setPastRecords] = useState([]);



    const getAllParties = useFetch("getAllParties");

    const submit = async () => {
        if (!type || !partyId) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);
        let response = await PostApi("nationalReport", { partyId, type });
        console.log(response)

        if (response.data && response.data.status === "1") {
            setReportData(response?.data?.data);
            setLoading(false);
        } else {
            error_toaster("No Result found!");
            setLoading(false);
        }
    };

    // Helper function to check if a value is an array and is not empty
    const isArrayAndNotEmpty = (value) => Array.isArray(value) && value.length > 0;
    useEffect(() => {


        if (reportData) {
            const formattedData = reportData?.regions?.map((party) => ({
                name: party.regionName,
                totalDistricts: party.totalDistricts,
                totalConstituencies: party.totalConstituencies,
                totalVoters: party.totalVoters,
                totalPollingStations: party.totalPollingStations,
                totalVoteCast: party.totalVoteCast,

            }));
            setList(formattedData);
            const record = reportData?.pastResults?.map((party) => ({
                year: party.year,
                votes: party.votes,
                candidate: party.candidate,
                percentage: `${party.percentage}%` ?? "0",
                district: party.constituency?.district?.name,
                region: party?.region?.name,
                party: party?.party?.name,
                
                createdAt: formatDate(party.createdAt)

            }));
            const pastTopParties = reportData?.pastRecords?.map((record) => ({
                year: record.year,
                topParties: record.topParties.map((party) => ({
                    candidate: party.candidate,
                    partyName: party.partyName,
                    votes: party.votes,
                    percentage: party.percentage ?? "0%",
                    partyImage: party.partyImage
                }))
            }));
            setPastRecords(pastTopParties);
            setPastResult(record)
        }
    }, [reportData]);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Districts",
            dataIndex: "totalDistricts",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Constituencies",
            dataIndex: "totalConstituencies",
        },
        {
            title: "Total P.S",
            dataIndex: "totalPollingStations",
        },
        {
            title: "Total Voters",
            dataIndex: "totalVoters",
        },
        {
            title: "Total Valid Votes Cast",
            dataIndex: "totalVoteCast",
        },


    ];
    const topPartiesColumns = [
        {
            title: "Year",
            dataIndex: "year",
            key: "year"
        },
        {
            title: "Top Parties",
            dataIndex: "topParties",
            key: "topParties",
            render: (_, record) =>
                record.topParties.map((party, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                        <img src={IMAGE_URL + party.partyImage} alt={party.partyName} style={{ height: "30px", marginRight: "10px" }} />
                        <span><strong>{party.candidate}</strong> ({party.partyName}): {party.votes} votes, {party.percentage}</span>
                    </div>
                ))
        }
    ];
    const pastColumns = [
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Candidate",
            dataIndex: "candidate",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Votes",
            dataIndex: "votes",
        },
        {
            title: "% of Votes",
            dataIndex: "percentage",
           
           
        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },

    ];

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select
                                            name="candidateType"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Party</label>
                                        <select
                                            name="party"
                                            value={partyId}
                                            onChange={(e) => setPartyId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Party</option>
                                            {getAllParties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>
                                                    {party.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <button onClick={submit} className="btn btn-primary mt-4">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Show report details */}
                    {reportData && (
                        <>
                            <div className="row mb-4">
                                {/* Region Count */}
                                <div className="col-md-3">
                                    <div className="card text-white bg-primary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Regions</h5>
                                            <p className="card-text">{reportData?.regionCount || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* District Count */}
                                {/* <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Districts</h5>
                                            <p className="card-text">{reportData?.districtCount || 0}</p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* Constituency Count */}
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Constituencies</h5>
                                            <p className="card-text">{reportData?.constituencyCount || 0}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Votes Cast</h5>
                                            <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Total Rejected Votes */}

                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Rejected Votes</h5>
                                            <p className="card-text">{reportData?.data?.totalWrongVotes || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Polling Stations</h5>
                                            <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with results</h5>
                                            <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with no results</h5>
                                            <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S %</h5>
                                            <p className="card-text"> {(
                                                ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                                                    Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                                            ).toFixed(2)}%</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Table for displaying candidate or party data */}
                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">{type === "Presidential" ? "Candidates Data" : "Parties Data"}</h5>
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                {type === "Presidential" ? (
                                                    <>
                                                        <th>Candidate Name</th>
                                                        <th>Party Name</th>
                                                        <th>Total Votes Secured</th>
                                                        <th>Constituencies Won</th>
                                                        <th>Vote Percentage</th>
                                                    </>
                                                ) : (
                                                    <>
                                                        <th>Party Name</th>
                                                        <th>Total Votes Secured</th>
                                                        <th>Constituencies Won</th>
                                                        <th>Constituencies Lost</th>
                                                        <th>Vote Percentage</th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {type === "Presidential" ? (
                                                isArrayAndNotEmpty(reportData?.data?.candidates) ? (
                                                    reportData.data.candidates.map((candidate) => (
                                                        <tr key={candidate?.candidateName}>
                                                            <td>{candidate?.candidateName || "N/A"}</td>
                                                            <td>{candidate?.partyName || "N/A"}</td>
                                                            <td>{candidate?.totalVotesSecured || 0}</td>
                                                            <td>{candidate?.constituenciesWon || 0}</td>
                                                            <td>{candidate?.votePercentage || "0.00%"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No data available</td>
                                                    </tr>
                                                )
                                            ) : (
                                                // Check the correct path for partyData
                                                isArrayAndNotEmpty(reportData?.data?.partyData) ? (
                                                    reportData.data.partyData?.map((party) => (
                                                        <tr key={party?.partyName}>
                                                            <td>{party?.partyName || "N/A"}</td>
                                                            <td>{party?.totalVotesSecured || 0}</td>
                                                            <td>{party?.constituenciesWon || 0}</td>
                                                            <td>{party?.constituenciesLost || 0}</td>
                                                            <td>{party?.votePercentage || "0.00%"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No data available</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Regions Information</h5>
                                    <Table
                                        columns={columns}
                                        dataSource={list}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                            <div className="card mb-4 shadow">
                        <div className="card-body">
                            <h5 className="card-title">Top Parties in Past Records</h5>
                            <Table
                                columns={topPartiesColumns}
                                dataSource={pastRecords}
                                pagination={{ pageSize: 5 }}
                            />
                        </div>
                    </div>
                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Past Results</h5>
                                    <Table
                                        columns={pastColumns}
                                        dataSource={pastResult}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default NationalReport;
