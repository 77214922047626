import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Table from "../../core/pagination/datatable";
import Chart from "react-apexcharts";
import formatDate from "../../Utils/DateFormat";
import { IMAGE_URL } from "../../Utils/urls";


ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const RegionReport = () => {
    const [districtId, setDistrictId] = useState(null);
    const [partyId, setPartyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);
    const [chartList, setChartList] = useState([]);
    const [pastResult, setPastResult] = useState([]);
    const [pastRecords, setPastRecords] = useState([]);

    const { apiData } = useFetch("getAllRegions");
    const getAllParties = useFetch("getAllParties");

    const districtChange = async (id) => {
        setDistrictId(id);
    };

    const submit = async () => {
        if (!type || !partyId || !districtId) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);
        let response = await PostApi("regionReport", { partyId, regionId: districtId, type });
        console.log(response?.data?.data?.chartData)
        if (response.data && response.data.status === "1") {

            setReportData(response?.data?.data);
            setChartList(response?.data?.data?.chartData)
            // setList(response?.data?.data?.data?.constituencies); // Store constituencies data for chart
            setLoading(false);
        } else {
            error_toaster("No Result found!");
            setLoading(false);
        }
    };
    const [chartOptions, setChartOptions] = useState({
        series: [
            { name: "Parliamentary", data: [] },
            { name: "Presidential", data: [] },
        ],
        colors: ["#28C76F", "#EA5455"],
        chart: {
            type: "bar",
            height: 320,
            stacked: true,
            zoom: { enabled: true },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "all",
                columnWidth: "20%",
            },
        },
        dataLabels: { enabled: false },
        yaxis: { min: 0, max: 300, tickAmount: 5 },
        xaxis: { categories: [] },
        legend: { show: false },
        fill: { opacity: 1 },
    });

    useEffect(() => {
        if (chartList && chartList.length > 0) {
            const categories = []; // To store party names
            const seriesData = []; // To store votes secured for each party
    
            let maxVote = 300;
    
            // Iterate through the chartList to extract party names and their votes secured
            chartList.forEach((party) => {
                categories.push(party.partyName);
                const votesSecured = parseInt(party.totalVotesSecured, 10) || 0;
                seriesData.push(votesSecured);
                maxVote = Math.max(maxVote, votesSecured);
            });
    
            // Create a single series array with the party names and vote counts
            const seriesArray = [
                {
                    name: "Total Votes Secured", // This is the label for the series in the chart
                    data: seriesData, // Set the data as the votes secured
                },
            ];
    
            // Update the chart options with the new series and categories
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: seriesArray, // Use the constructed series array
                xaxis: { categories: categories.length > 0 ? categories : [""] }, // Set the categories as the party names
                yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Adjust the y-axis max value
            }));
        }
    }, [chartList]);
    useEffect(() => {


        if (reportData) {
            const formattedData = reportData?.constituencies?.map((party) => ({
                name: party.name,
                totalVoters: party.totalVoters,
                totalPollingStations: party.totalPollingStations,
                totalVotesCast: party.totalVotesSecured ? party.totalVotesSecured:0,

            }));
            setList(formattedData);
            const record = reportData?.pastResults?.map((party) => ({
                year: party.year,
                votes: party.votes,
                candidate: party.candidate,
                percentage: `${party.percentage}%` ?? "0",
                region: party.constituency?.district?.region?.name,
                party: party?.party?.name,
                createdAt: formatDate(party.createdAt)

            }));
            const pastTopParties = reportData?.pastRecords?.map((record) => ({
                year: record.year,
                topParties: record.topParties.map((party) => ({
                    candidate: party.candidate,
                    partyName: party.partyName,
                    votes: party.votes,
                    percentage: party.percentage ?? "0%",
                    partyImage: party.partyImage
                }))
            }));
            
            setPastRecords(pastTopParties);
            setPastResult(record)
        }
    }, [reportData]);
   
   
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "No. of Polling Stations",
            dataIndex: "totalPollingStations",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Voters",
            dataIndex: "totalVoters",
        },
        {
            title: "Votes Cast",
            dataIndex: "totalVotesCast",
        },


    ];

    const topPartiesColumns = [
        {
            title: "Year",
            dataIndex: "year",
            key: "year"
        },
        {
            title: "Top Parties",
            dataIndex: "topParties",
            key: "topParties",
            render: (_, record) =>
                record.topParties.map((party, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                        <img src={IMAGE_URL + party.partyImage} alt={party.partyName} style={{ height: "30px", marginRight: "10px" }} />
                        <span><strong>{party.candidate}</strong> ({party.partyName}): {party.votes} votes, {party.percentage}</span>
                    </div>
                ))
        }
    ];
    // Get unique party names from the reportData
    const uniqueParties = reportData?.data?.constituencies?.reduce((acc, cons) => {
        cons.parties.forEach((party) => {
            if (!acc.includes(party.partyName)) {
                acc.push(party.partyName);
            }
        });
        return acc;
    }, []);
    const pastColumns = [
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Candidate",
            dataIndex: "candidate",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Votes",
            dataIndex: "votes",
        },
        {
            title: "% of Votes",
            dataIndex: "percentage",
           
        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },

    ];
    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select
                                            name="candidateType"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Party</label>
                                        <select
                                            name="party"
                                            value={partyId}
                                            onChange={(e) => setPartyId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Party</option>
                                            {getAllParties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>
                                                    {party.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Region</label>
                                        <select
                                            name="district"
                                            value={districtId}
                                            onChange={(e) => districtChange(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Region</option>
                                            {apiData?.data?.regions?.map((district) => (
                                                <option key={district.id} value={district.id}>
                                                    {district.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">
                                Submit
                            </button>
                        </div>
                    </div>

                    {/* Show report details */}
                    {reportData && (
                        <>
                            <div className="row mb-4">
                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Districts</h5>
                                            <p className="card-text">{reportData?.districts}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Constituencies</h5>
                                            <p className="card-text">{reportData?.constLength}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Votes Cast</h5>
                                            <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Rejected Votes</h5>
                                            <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Polling Stations</h5>
                                            <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with results</h5>
                                            <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with no results</h5>
                                            <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S %</h5>
                                            <p className="card-text"> {(
                                                    ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                                                        Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                                                ).toFixed(2)}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Table for displaying constituencies and party votes */}
                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Constituency Name</th>
                                                {uniqueParties?.map((partyName) => (
                                                    <th key={partyName}>{partyName}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData?.data?.constituencies?.map((constituency) => (
                                                <tr key={constituency.constituencyName}>
                                                    <td>{constituency.constituencyName}</td>
                                                    {uniqueParties?.map((partyName) => {
                                                        const party = constituency.parties.find((p) => p.partyName === partyName);
                                                        return (
                                                            <td key={partyName}>
                                                                {party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-body bg-white shadow my-3">
                                {chartList?.length > 0 && (
                                    <Chart
                                        options={chartOptions}
                                        series={chartOptions.series}
                                        type="bar"
                                        height={320}
                                    />
                                )}
                            </div>

                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Constituency Information</h5>
                                    <Table
                                        columns={columns}
                                        dataSource={list}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                            <div className="card mb-4 shadow">
                        <div className="card-body">
                            <h5 className="card-title">Top Parties in Past Records</h5>
                            <Table
                                columns={topPartiesColumns}
                                dataSource={pastRecords}
                                pagination={{ pageSize: 5 }}
                            />
                        </div>
                    </div>
                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Past Results</h5>
                                    <Table
                                        columns={pastColumns}
                                        dataSource={pastResult}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default RegionReport;
