import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PostApi } from "../../ApiClient/PostApi";
import { IMAGE_URL } from "../../Utils/urls";
import { error_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const ControllerResultDisplay = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedConstituency, setSelectedConstituency] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [text, setText] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [pollingStations, setPollingStations] = useState([]);
  const [type, setType] = useState(null);
  const [reportData, setReportData] = useState(null); // State to hold the API response data

  const getAllRegions = useFetch("getAllRegions");
useEffect(()=>{
    setPartyId(localStorage.getItem("partyId"));
},[partyId])

  const handleRegionChange = async (regionId) => {
    setSelectedRegion(regionId);
    setSelectedDistrict(null);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("regionDistricts", { regionId });
      setDistricts(response.data.data.districts || []);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const handleDistrictChange = async (districtId) => {
    setSelectedDistrict(districtId);
    setSelectedConstituency(null);
    setPollingStations([]);

    try {
      const response = await PostApi("districtConsistuency", { districtId });
      setConstituencies(response.data.data.consistuencies || []);
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };

  const handleConstituencyChange = async (constituencyId) => {
    setSelectedConstituency(constituencyId);
    setPollingStations([]);

    try {
      const response = await PostApi("consistuencyPollingStationsForRecords", { constituencyId });
      setPollingStations(response.data.data.pollingstations || []);
    } catch (error) {
      console.error("Error fetching polling stations:", error);
    }
  };
  const uniqueParties = reportData?.data?.constituencies?.reduce((acc, cons) => {
    cons.parties.forEach((party) => {
      if (!acc.includes(party.partyName)) {
        acc.push(party.partyName);
      }
    });
    return acc;
  }, []);
  const submit = async () => {
    try {
      setLoading(true)
      const response = await PostApi("resultDisplayReport", {
        regionId: selectedRegion,
        districtId: selectedDistrict,
        constituencyId: selectedConstituency,
        pollingStationId: selectedStation,
        type: type,
        partyId
      });
      if (response.data.message == "pollingstation") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      if (response.data.message == "constituency") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      else if (response.data.message == "district") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }
      else if (response.data.message == "region") {
        setText(response.data.message);
        setReportData(response.data.data); // Set the fetched report data
      }

      setLoading(false)
    } catch (error) {
      error_toaster(error.message);
      setLoading(false);
    }
  };
  const getWinningCandidate = () => {
    if (!reportData || !reportData.pollingStationCandidates) return null;
    const winningCandidate = reportData.pollingStationCandidates.reduce((max, candidate) => (parseInt(candidate.voteSecured) > parseInt(max.voteSecured) ? candidate : max), reportData.pollingStationCandidates[0]);
    return winningCandidate;
};

const winningCandidate = getWinningCandidate();
  const toWords = (num) => {
    // Convert number to words (placeholder function)
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "Zero";
  };
  const calculateValidVotes = () => {
    if (!reportData || !reportData?.data?.data?.pollingStationCandidates) return 0;

    return reportData?.data?.data.pollingStationCandidates.reduce((sum, candidateData) => {
        const { voteSecured } = candidateData;
        const secured = voteSecured !== null ? parseInt(voteSecured) : 0;
        return sum + secured;
    }, 0);
};
  console.log(reportData?.data?.data)

  return (
    <div className="page-wrapper">
      {
        loading ? <Loader /> : <div>
          <ToastContainer />
          <div className="content">
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Results Input & Report</h4>
                  <h6>View and Analyze Results</h6>
                </div>
              </div>
            </div>

            {/* Select Inputs for Regions, Districts, Constituencies, and Polling Stations */}
            <div className="row mb-4">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Candidate Type</label>
                  <select
                    name="candidateType"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Candidate Type</option>
                    <option value="Parliamentary">Parliamentary</option>
                    <option value="Presidential">Presidential</option>
                  </select>
                </div>
              </div>
            
              <div className="col-md-3">
                <label>Region</label>
                <select className="form-control" value={selectedRegion || ""} onChange={(e) => handleRegionChange(e.target.value)}>
                  <option value="">Select Region</option>
                  {getAllRegions?.apiData?.data?.regions?.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>District</label>
                <select className="form-control" value={selectedDistrict || ""} onChange={(e) => handleDistrictChange(e.target.value)} disabled={!selectedRegion}>
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Constituency</label>
                <select className="form-control" value={selectedConstituency || ""} onChange={(e) => handleConstituencyChange(e.target.value)} disabled={!selectedDistrict}>
                  <option value="">Select Constituency</option>
                  {constituencies.map((constituency) => (
                    <option key={constituency.id} value={constituency.id}>
                      {constituency.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label>Polling Station</label>
                <select onChange={(e) => setSelectedStation(e.target.value)} defaultValue={selectedStation} className="form-control" disabled={!selectedConstituency}>
                  <option value="">Select Polling Station</option>
                  {pollingStations.map((station) => (
                    <option key={station.id} value={station.id}>
                      {station.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor=""></label>
                <div>
                  <button onClick={submit} className="btn btn-warning">Submit</button>
                </div>
              </div>
            </div>

            {/* Display Report Data */}
            {reportData && text === "pollingstation" && (
              <>
                {/* Polling Station Summary Section */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Polling Station</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Voters</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.totalVoters}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Constituency</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Region</h5>
                        <p className="card-text">{reportData?.data?.data?.dd?.constituency?.district?.region?.name}</p>
                      </div>
                    </div>
                  </div>

                  {/* Winner Candidate Section */}
                  {winningCandidate && (
                    <>
                      <div className="col-md-3">
                        <div className="card text-white bg-info mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Candidate</h5>
                            <p className="card-text">{winningCandidate.candidate.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-secondary mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Votes</h5>
                            <p className="card-text">{winningCandidate.voteSecured}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card text-white bg-success mb-3">
                          <div className="card-body">
                            <h5 className="card-title">Winner Party</h5>
                            <p className="card-text">{winningCandidate.candidate.party.name}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* Polling Station Candidates Table */}
                <div>
                  <div className="card shadow">
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>Candidate Name</th>
                            <th>Party</th>
                            <th>No. Votes Secured (In Figures)</th>
                            <th>No. Votes Secured (In Words)</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {reportData?.data?.data?.pollingStationCandidates?.map((candidateData) => {
                            const { candidate, voteSecured } = candidateData;
                            return (
                              <tr key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>
                                  <div>
                                    <img
                                      src={IMAGE_URL + candidate?.party?.image}
                                      style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                      alt={candidate?.party?.name}
                                    />
                                  </div>
                                </td>
                                <td>{voteSecured ?? 0}</td>
                                <td>{toWords(voteSecured ?? 0)}</td>
                                
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan="2">Total Valid Ballot</td>
                            <td>{calculateValidVotes()}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Rejected Ballot</td>
                            <td>{reportData?.data?.data?.resultData?.wrongVotes}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colSpan="2">Total Ballot Cast</td>
                            <td>{parseInt(calculateValidVotes()) + parseInt(reportData?.data?.data?.resultData?.wrongVotes ?? 0)}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            {reportData && text == "constituency" && (
              <div className="report-section">
                <div className="row">

                  <div className="col-md-3">
                    <div className="card text-white bg-secondary">
                      <div className="card-body">
                        <h5 className="card-title">Total Polling Stations</h5>
                        <p className="card-text">{reportData.data.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info">
                      <div className="card-body">
                        <h5 className="card-title">P.S with Results</h5>
                        <p className="card-text">{reportData.data.stationsWithResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success">
                      <div className="card-body">
                        <h5 className="card-title">P.S without Results</h5>
                        <p className="card-text">{reportData.data.stationsWithoutResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning">
                      <div className="card-body">
                        <h5 className="card-title">% of P.S</h5>
                        <p className="card-text">{reportData.data.pollingStationResultPercentage}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Candidates Information</h5>
                    <div className="table-responsive"></div>
                    <table className="table table-striped table-bordered mt-4">
                      <thead className="thead-dark">
                        <tr>
                          <th>Candidate Name</th>
                          <th>Party</th>

                          <th>No. Votes Secured (In Figures)</th>
                          <th>No. Votes Secured (In Words)</th>
                          <th>% of Votes Secured</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.data.candidates.map((candidate) => (
                          <tr key={candidate.id}>
                            <td>{candidate?.name}</td>
                            <td>
                              <div>
                                <img
                                  style={{ height: "40px", width: "40px", objectFit: "contain" }}
                                  src={IMAGE_URL + candidate.party.image}
                                  alt={candidate?.party?.name}
                                />
                              </div>
                            </td>

                            <td>{candidate.totalVotesSecured}</td>
                            <td>{toWords(candidate.totalVotesSecured)}</td>
                            <td>{candidate.votePercentage}%</td>
                          </tr>
                        ))}
                        {/* Display total valid votes, wrong votes, and total vote cast */}
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Valid Votes</strong></td>
                          <td><strong>{reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)}</strong></td>
                          {/* <td><strong>{toWords(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0))}</strong></td> */}
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Rejected Votes</strong></td>
                          <td colSpan="3"><strong>{reportData.data.totalWrongVotes}</strong></td>
                        </tr>
                        <tr>
                          <td colSpan="3" className="text-right"><strong>Total Votes Cast</strong></td>
                          <td colSpan="3"><strong>{parseInt(reportData.data.candidates.reduce((acc, candidate) => acc + candidate.totalVotesSecured, 0)) + parseInt(reportData.data.totalWrongVotes)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "district" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">District</h5>
                        <p className="card-text">{reportData?.districtData?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  votes:{party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {reportData && text == "region" && (
              <div className="report-section">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Districts</h5>
                        <p className="card-text">{reportData?.districts}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of P.S</h5>
                        <p className="card-text">{reportData?.stationResults?.totalPollingStations}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S with no result</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithoutResults}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-warning mb-3">
                      <div className="card-body">
                        <h5 className="card-title">P.S result %</h5>
                        <p className="card-text">{reportData?.stationResults?.pollingStationsWithResultsPercentage}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-info mb-3">
                      <div className="card-body">
                        <h5 className="card-title">No. of Constituencies</h5>
                        <p className="card-text">{reportData?.constLength}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-success mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Votes Cast</h5>
                        <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card text-white bg-secondary mb-3">
                      <div className="card-body">
                        <h5 className="card-title">Total Rejected Votes</h5>
                        <p className="card-text">{reportData?.data?.totalWrongVotes}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidates Table */}
                <div className="card mb-4 shadow">
                  <div className="card-body">
                    <h5 className="card-title">Constituency and Party Votes ({type})</h5>
                    <table className="table table-striped table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Constituency Name</th>
                          {uniqueParties?.map((partyName) => (
                            <th key={partyName}>{partyName}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.data?.constituencies?.map((constituency) => (
                          <tr key={constituency.constituencyName}>
                            <td>{constituency.constituencyName}</td>
                            {uniqueParties?.map((partyName) => {
                              const party = constituency.parties.find((p) => p.partyName === partyName);
                              return (
                                <td key={partyName}>
                                  votes:{party ? party.totalVotesSecured : 0}  - ({party ? party.votePercentage : 0})
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default ControllerResultDisplay;
