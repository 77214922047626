import React, { useEffect, useState, useRef } from "react";
import { Search, Edit, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { ToastContainer } from "react-toastify";

const PollingManagement = () => {
    const [pollingData, setPollingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // New state for filtered data
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [code, setCode] = useState("");
    const [areaId, setAreaId] = useState("");
    const [totalVoters, setTotalVoters] = useState("");
    const [id, setId] = useState("");
    const [year, setYear] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search query
    const fileInputRef = useRef(null); // Ref for file input
    const { apiData, reFetch } = useFetch("getPollingStations");
    const areas = useFetch("getAllConsistuencies");

    useEffect(() => {
        setRole(localStorage.getItem("role"));
        setPermissions(JSON.parse(localStorage.getItem("permissions")));
        if (apiData && apiData.data && apiData.data.stations) {
            let formattedData = apiData.data.stations.map((station) => ({
                key: station.id,
                PollingStationName: station.name,
                PollingStationCode: station.code,
                constituencyName: station.constituency?.name,
                areaId: station.constituency?.id,
                NoOfVoters: station.totalVoters,
                Year: station.year,
            }));

            setPollingData(formattedData);
            setFilteredData(formattedData); // Initialize filtered data
        }
    }, [apiData, role, permissions]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setName("");
        setYear("");
        setCode("");
        setAreaId("");
        setTotalVoters("");
    };

    // Search functionality
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filteredRecords = pollingData.filter((record) => {
            // Use match for case-insensitive matching
            const regex = new RegExp(query, "i");
            return record.PollingStationName.match(regex) || record.PollingStationCode.match(regex);
        });

        setFilteredData(filteredRecords); // Update filtered data
    };

    const columns = [
        {
            title: "Polling Station Name",
            dataIndex: "PollingStationName",
            sorter: (a, b) => a.PollingStationName.length - b.PollingStationName.length,
        },
        {
            title: "Polling Station Code",
            dataIndex: "PollingStationCode",
            sorter: (a, b) => a.PollingStationCode.length - b.PollingStationCode.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "Constituency",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.length - b.constituencyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "90px" }}>{status}</span>,
        },
        {
            title: "No. of Voters",
            dataIndex: "NoOfVoters",
            sorter: (a, b) => a.NoOfVoters - b.NoOfVoters,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {(role === "super admin" || (role === "user" && permissions.includes("edit_pollingstation"))) && (
                            <Link className="me-2 p-2" to="#" onClick={() => editModal(record)}>
                                <Edit className="feather-edit" />
                            </Link>
                        )}

                        {(role === "super admin" || (role === "user" && permissions.includes("delete_pollingstation"))) && (
                            <Link className="confirm-text p-2" to="#" onClick={() => deletePollingStation(record.key)}>
                                <Trash2 className="feather-trash-2" />
                            </Link>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const addPollingStation = async (e) => {
        e.preventDefault();
        if (name === "" || code === "" || areaId === "" || totalVoters === "") {
            error_toaster("All fields are required!");
            return false;
        }
        setLoading(true);
        const response = await PostApi("addPollingStation", { name, code, areaId, totalVoters, year });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const updatePollingStation = async (e) => {
        e.preventDefault();
        if (name === "" || code === "" || areaId === "" || totalVoters === "") {
            error_toaster("All fields are required!");
            return false;
        }
        setLoading(true);
        const response = await PostApi("updatePollingStation", { id, name, code, areaId, totalVoters, year });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const deletePollingStation = async (id) => {
        setLoading(true);
        const response = await PostApi("deletePollingStation", { id });
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const editModal = (obj) => {
        setName(obj.PollingStationName);
        setId(obj.key);
        setYear(obj.Year);
        setCode(obj.PollingStationCode);
        setAreaId(obj.areaId);
        setTotalVoters(obj.NoOfVoters);
        setShow(true);
        setEdit(true);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("addPollingStationsFromSheet", formData, true);
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger file input click
    };

    return (
        <div className="page-wrapper">
            <ToastContainer />
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Polling Station Management</h4>
                                <h6>Manage Polling Stations</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            {role === "super admin" && (
                                <li>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                                        <button className="btn btn-link" onClick={handleUploadClick}>
                                            <Upload />
                                        </button>
                                    </OverlayTrigger>
                                </li>
                            )}
                        </ul>
                        <div className="page-btn">
                            {(role === "super admin" || (role === "user" && permissions.includes("add_pollingstation"))) && (
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Polling Station list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search Polling Station Name or Code"
                                            className="form-control form-control-sm formsearch"
                                            value={searchQuery}
                                            onChange={handleSearchChange} // Add onChange handler for search
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>
                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                    {/* Add/Edit Polling Station Modal */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{edit ? "Update Polling Station" : "Add Polling Station"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Polling Station Name</label>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Polling Station Name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Polling Station Code</label>
                                    <input
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Polling Station Code"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Consistuency</label>
                                    <select
                                        value={areaId}
                                        onChange={(e) => setAreaId(e.target.value)}
                                        type="text"
                                        className="form-control"
                                    >
                                        <option value="">Select Consistuency</option>
                                        {areas?.apiData?.data?.consistuencies?.map((area) => (
                                            <option key={area.id} value={area.id}>{area.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Total Voters</label>
                                    <input
                                        value={totalVoters}
                                        onChange={(e) => setTotalVoters(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Total Voters"
                                    />
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center w-100 gap-3">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                    Close
                                </button>
                                {edit ? (
                                    <button onClick={updatePollingStation} type="button" className="btn btn-primary">
                                        Update
                                    </button>
                                ) : (
                                    <button onClick={addPollingStation} type="button" className="btn btn-primary">
                                        Save
                                    </button>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default PollingManagement;
