import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Chart from "react-apexcharts";
import { IMAGE_URL } from "../../Utils/urls";
import { toWords } from 'number-to-words';
import Select from 'react-select';

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const ControllerPollingStationReport = () => {
    const [stationId, setStationId] = useState(null);
    const [partyId, setPartyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);

    useEffect(()=>{
        setPartyId(localStorage.getItem("partyId"))
    },[partyId])

    const { apiData } = useFetch("getPollingStations");

    const submit = async () => {
        if (!type || !partyId || !stationId) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);

        try {
            let response = await PostApi("submitReport", { partyId, type: type, stationId: stationId });

            if (response.data?.status === "1") {
                const reportResponseData = response?.data?.data?.data?.data;  // Adjusted to extract nested data

                if (reportResponseData?.resultData == null) {
                    error_toaster("Sorry! Result not uploaded yet");
                    setLoading(false);
                    return false;
                }

                // Set the required data to state
                setList(reportResponseData.chartData);
                setReportData(reportResponseData); // Save the entire report data to the state
            }
        } catch (error) {
            error_toaster("Error fetching report data");
        } finally {
            setLoading(false);
        }
    };

    const calculatePercentage = (securedVotes, totalVoters) => {
        return ((securedVotes / totalVoters) * 100).toFixed(2);
    };
console.log(JSON.stringify(list))
    const calculateValidVotes = () => {
        if (!reportData || !reportData.pollingStationCandidates) return 0;

        return reportData.pollingStationCandidates.reduce((sum, candidateData) => {
            const { voteSecured } = candidateData;
            const secured = voteSecured !== null ? parseInt(voteSecured) : 0;
            return sum + secured;
        }, 0);
    };

    const getWinningCandidate = () => {
        if (!reportData || !reportData.pollingStationCandidates) return null;
        const winningCandidate = reportData.pollingStationCandidates.reduce((max, candidate) => (parseInt(candidate.voteSecured) > parseInt(max.voteSecured) ? candidate : max), reportData.pollingStationCandidates[0]);
        return winningCandidate;
    };

    const winningCandidate = getWinningCandidate();

    const [chartOptions, setChartOptions] = useState({
        series: [{ name: "Votes", data: [] }],
        colors: ["#28C76F", "#EA5455"],
        chart: {
            type: "bar",
            height: 320,
            stacked: true,
            zoom: { enabled: true },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "all",
                columnWidth: "20%",
            },
        },
        dataLabels: { enabled: false },
        yaxis: { min: 0, max: 300, tickAmount: 5 },
        xaxis: { categories: [] },
        legend: { show: false },
        fill: { opacity: 1 },
    });

    useEffect(() => {
        if (list && list.length > 0) {
            const categories = [];
            const presidentialVotes = [];
            const parliamentaryVotes = [];
            let maxVote = 300;
    
            list.forEach((party) => {
                categories.push(party.name);
    
                // Get votes for Presidential and Parliamentary types
                const presidentialVote = parseInt(party.data.find((vote) => vote.name === "Presidential")?.y || 0, 10);
                const parliamentaryVote = parseInt(party.data.find((vote) => vote.name === "Parliamentary")?.y || 0, 10);
    
                // Store votes separately
                presidentialVotes.push(presidentialVote);
                parliamentaryVotes.push(parliamentaryVote);
    
                // Update maxVote for scaling the graph
                maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
            });
    
            // Set series based on the selected type
            const selectedSeries = type === "Presidential"
                ? [{ name: "Presidential", data: presidentialVotes }]
                : [{ name: "Parliamentary", data: parliamentaryVotes }];
    
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: selectedSeries, // Use the selected series based on type
                xaxis: { categories: categories.length > 0 ? categories : [""] },
                yaxis: { ...prevOptions.yaxis, max: maxVote + 50 },
            }));
        }
    }, [list, type]);
    

    const stationOptions = apiData?.data?.stations?.map((station) => ({
        value: station.id,
        label: `${station.name} - ${station.code}`,
    })) || [];

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select name="candidateType" value={type} onChange={(e) => setType(e.target.value)} className="form-control">
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                               
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Polling Station</label>
                                        <Select
                                            options={stationOptions}
                                            value={stationOptions.find(option => option.value === stationId)}
                                            onChange={(option) => setStationId(option.value)}
                                            isSearchable={true}
                                            placeholder="Select Polling Station"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">Submit</button>
                        </div>
                    </div>

                    {/* Show polling station details in button-style cards */}
                    {reportData && (
                        <div className="row mb-4">
                            <div className="col-md-3">
                                <div className="card text-white bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Polling Station</h5>
                                        <p className="card-text">{reportData.dd?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Voters</h5>
                                        <p className="card-text">{reportData.dd?.totalVoters}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-secondary mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Constituency</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">District</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.district?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Region</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.district?.region?.name}</p>
                                    </div>
                                </div>
                            </div>
                            {winningCandidate && (
                                <>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-info mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Candidate</h5>
                                                <p className="card-text">{winningCandidate.candidate.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-secondary mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Votes</h5>
                                                <p className="card-text">{winningCandidate.voteSecured}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-success mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Party</h5>
                                                <p className="card-text">{winningCandidate.candidate.party.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {/* Display candidate data */}
                    {reportData && (
                        <div>
                            <div className="card shadow">
                                <div className="card-body">
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Candidate Name</th>
                                                <th>Party</th>
                                                <th>No. Votes Secured (In Figures)</th>
                                                <th>No. Votes Secured (In Words)</th>
                                                <th>% of Votes Secured</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.pollingStationCandidates.map((candidateData) => {
                                                const { candidate, voteSecured } = candidateData;
                                                return (
                                                    <tr key={candidate.id}>
                                                        <td>{candidate.name}</td>
                                                        <td>
                                                            <div>
                                                                <img src={IMAGE_URL + candidate?.party?.image} style={{ height: "40px", width: "40px", objectFit: "contain" }} alt={candidate?.party?.name} />
                                                            </div>
                                                        </td>
                                                        <td>{voteSecured ? voteSecured : 0}</td>
                                                        <td>{toWords(voteSecured ?? 0)}</td>
                                                        <td>{calculatePercentage(voteSecured, calculateValidVotes())}%</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td colSpan="2">Total Valid Ballot</td>
                                                <td>{calculateValidVotes()}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">Total Rejected Ballot</td>
                                                <td>{reportData.resultData.wrongVotes}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">Total Ballot Cast</td>
                                                <td>{parseInt(calculateValidVotes()) + parseInt(reportData.resultData.wrongVotes)}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Chart */}
                    <div className="card-body bg-white shadow my-3">
                        {list?.length > 0 && (
                            <Chart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="bar"
                                height={320}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ControllerPollingStationReport;
