import React, { useEffect, useState, useMemo } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";

const PastRecord = () => {
    const [recordData, setRecordData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // New state for filtered data
    const [searchQuery, setSearchQuery] = useState(""); // New state for search query
    const [candidateTypeFilter, setCandidateTypeFilter] = useState(""); // New state for candidate type filter
    const [constituencyFilter, setConstituencyFilter] = useState(""); // New state for constituency filter
    const [districtFilter, setDistrictFilter] = useState(""); // New state for district filter

    const { apiData } = useFetch(`getAllRecords`); // Replace with actual API endpoint
    const getAllConsistuencies = useFetch(`getAllConsistuencies`); // Replace with actual API endpoint
    const getAllDistrictsForConsistuency = useFetch(`getAllDistrictsForConsistuency`); // Replace with actual API endpoint

    const constituenciesList = getAllConsistuencies?.apiData?.data?.consistuencies || [];
    const districtsList = getAllDistrictsForConsistuency?.apiData?.data?.districts || [];

    const transformedData = useMemo(() => {
        if (apiData?.data?.records) {
            return apiData?.data?.records.map((record) => ({
                key: record?.id,
                year: record?.year,
                voters: record?.votes,
                candidate: record?.candidate,
                candidatetype: record?.candidatetype?.name,
                candidatetypeId: record?.candidatetypeId,
                percentage: `${record?.percentage}%`,
                constituency: record?.constituency?.name,
                constituencyId: record?.constituency?.id,
                areaName: record?.constituency?.areaName,
                areaCode: record?.constituency?.areaCode,
                district: record?.district?.name,
                districtId: record?.district?.id,
                region: record?.region?.name,
                party: record?.party?.name,
            }));
        }
        return [];
    }, [apiData]);

    useEffect(() => {
        setRecordData(transformedData);
        setFilteredData(transformedData); // Initialize filteredData with the full set of records
    }, [transformedData]);

    // Handle search query change
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterData(query, candidateTypeFilter, constituencyFilter, districtFilter);
    };

    // Handle candidate type change
    const handleCandidateTypeChange = (e) => {
        const selectedType = e.target.value;
        setCandidateTypeFilter(selectedType);
        filterData(searchQuery, selectedType, constituencyFilter, districtFilter);
    };

    // Handle constituency change
    const handleConstituencyChange = (e) => {
        const selectedConstituency = e.target.value;
        setConstituencyFilter(selectedConstituency);
        filterData(searchQuery, candidateTypeFilter, selectedConstituency, districtFilter);
    };

    // Handle district change
    const handleDistrictChange = (e) => {
        const selectedDistrict = e.target.value;
        setDistrictFilter(selectedDistrict);
        filterData(searchQuery, candidateTypeFilter, constituencyFilter, selectedDistrict);
    };

    // Function to filter data based on search query, candidate type, constituency, and district
    const filterData = (query, type, constituency, district) => {
        const filteredRecords = recordData.filter((record) => {
            const matchesQuery = record.candidate.toLowerCase().includes(query);
            const matchesType = type ? record.candidatetype === type : true;
            const matchesConstituency = constituency ? record.constituencyId.toString() === constituency : true;
            const matchesDistrict = district ? record.districtId.toString() === district : true;
            return matchesQuery && matchesType && matchesConstituency && matchesDistrict;
        });
        setFilteredData(filteredRecords);
    };

    const columns = [
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.year - b.year,
        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.party.length - b.party.length,
        },
        {
            title: "No. of Votes",
            dataIndex: "voters",
            sorter: (a, b) => a.voters - b.voters,
        },
        {
            title: "% of Votes",
            dataIndex: "percentage",
            sorter: (a, b) => a.voters - b.voters,
        },
        {
            title: "Candidate",
            dataIndex: "candidate",
            sorter: (a, b) => a.candidate.length - b.candidate.length,
        },
        {
            title: "Candidate Type",
            dataIndex: "candidatetype",
            sorter: (a, b) => a.candidate.length - b.candidate.length,
        },
        {
            title: "Constituency",
            dataIndex: "constituency",
            sorter: (a, b) => a.constituency.length - b.constituency.length,
        },
        {
            title: "Area Name",
            dataIndex: "areaName",
            sorter: (a, b) => a.constituency.length - b.constituency.length,
        },
        {
            title: "Area Code",
            dataIndex: "areaCode",
            sorter: (a, b) => a.constituency.length - b.constituency.length,
        },
        {
            title: "District",
            dataIndex: "district",
            sorter: (a, b) => a.district.length - b.district.length,
        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => a.region.length - b.region.length,
        },
     
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Record Management</h4>
                            <h6>Manage Records</h6>
                        </div>
                    </div>
                    <ul className="table-top-head"></ul>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search by Candidate Name"
                                        className="form-control form-control-sm formsearch "
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                                <div className="candidate-type-filter">
                                    <select
                                        value={candidateTypeFilter}
                                        onChange={handleCandidateTypeChange}
                                        className="form-control "
                                    >
                                        <option value="">All Candidate Types</option>
                                        <option value="Parliamentary">Parliamentary</option>
                                        <option value="Presidential">Presidential</option>
                                    </select>
                                </div>
                                {/* Constituency filter dropdown */}
                                <div className="constituency-filter mx-3">
                                    <select
                                        value={constituencyFilter}
                                        onChange={handleConstituencyChange}
                                        className="form-control"
                                    >
                                        <option value="">All Constituencies</option>
                                        {constituenciesList.map((cons) => (
                                            <option key={cons.id} value={cons.id}>
                                                {cons.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* District filter dropdown */}
                                <div className="district-filter">
                                    <select
                                        value={districtFilter}
                                        onChange={handleDistrictChange}
                                        className="form-control"
                                    >
                                        <option value="">All Districts</option>
                                        {districtsList.map((dist) => (
                                            <option key={dist.id} value={dist.id}>
                                                {dist.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table table-hover table-center mb-0 datatable"
                                columns={columns}
                                dataSource={filteredData} // Use filteredData instead of recordData
                                rowKey={(record) => record.key}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PastRecord;
