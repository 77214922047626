import React from "react";
import { Route, Navigate } from "react-router-dom";

import Dashboard from "../feature-module/dashboard/Dashboard";

const routes = all_routes;


import Signin from "../feature-module/pages/login/signin";

import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import ResetpasswordTwo from "../feature-module/pages/resetpassword/resetpasswordTwo";
import { all_routes } from "./all_routes";
import AdminManagment from "../feature-module/inventory/AdminManagment";
import ControllerManagment from "../feature-module/inventory/ControllerManagment";
import UserManagment from "../feature-module/inventory/UserManagment";
import PollingManagment from "../feature-module/inventory/PollingManagment";
import RegionManagment from "../feature-module/inventory/RegionManagment";
import DistrictManagement from "../feature-module/inventory/DistrictManagment";
import SystemSetting from "../feature-module/inventory/SystemSettings";
import Reports from "../feature-module/inventory/Report";
import Ballot from "../feature-module/inventory/Ballot";
import CandidateManagement from "../feature-module/inventory/CandidateManagement";
import Results from "../feature-module/inventory/Result";
import ConsistuencyManagment from "../feature-module/inventory/ConsistuencyManagment";
import ProtectedRoutes from "../Utils/ProtectedRoutes";
import AgentManagment from "../feature-module/inventory/AgentController";

import OtpManagment from "../feature-module/inventory/OtpManagment";
import UserDetails from "../feature-module/inventory/UserDetails";
import PartyManagement from "../feature-module/inventory/PartyManagment";
import AddController from "../feature-module/inventory/AddController";
import ControllerRegion from "../feature-module/inventory/ControllerRegion";
import ControllerDistrict from "../feature-module/inventory/ControllerDistrict";
import ControllerConsistuency from "../feature-module/inventory/ControllerConsistuency";
import ControllerPollingStation from "../feature-module/inventory/ControllerPollingStation";
import ResultDetails from "../feature-module/inventory/ResultDetails";
import AgentPollingStations from "../feature-module/inventory/AgentPollingStations";
import AgentDashboard from "../feature-module/dashboard/AgentDashboard";
import ControllerDashboard from "../feature-module/dashboard/ControllerDashboard";
import AgentResult from "../feature-module/inventory/AgentResult";
import ControllerResult from "../feature-module/inventory/ControllerResult";
import TeamManagment from "../feature-module/inventory/TeamManagment";
import Levels from "../feature-module/inventory/Designation";
import AdminController from "../feature-module/inventory/AdminController";
import AdminAgent from "../feature-module/inventory/AdminAgent";
import EditController from "../feature-module/inventory/EditController";
import AdminParty from "../feature-module/inventory/AdminParty";
import AdminCandidate from "../feature-module/inventory/AdminCandidate";
import News from "../feature-module/inventory/News";
import Session from "../feature-module/inventory/Session";
import AdminDashboard from "../feature-module/dashboard/AdminDashboard";
import Record from "../feature-module/inventory/Record";
import ControllerAgent from "../feature-module/inventory/ControllerAgent";
import ConstituencySelection from "../feature-module/inventory/ConstituencySelection";
import ConstituencyReport from "../feature-module/inventory/ConstituencyReport";
import DistrictReport from "../feature-module/inventory/DistrictReport";
import RegionReport from "../feature-module/inventory/RegionReport";
import NationalReport from "../feature-module/inventory/NationalReport";
import AdminPollingStationReport from "../feature-module/inventory/AdminPollingStationReport";
import AdminConstituencyReport from "../feature-module/inventory/AdminConstituencyReport";
import AdminDistrictReport from "../feature-module/inventory/AdminDistrictReport";
import AdminRegionReport from "../feature-module/inventory/AdminRegionReport";
import AdminNationalReport from "../feature-module/inventory/AdminNationalReport";
import ResultDisplay from "../feature-module/inventory/ResultDisplay";
import ControllerPollingStationReport from "../feature-module/inventory/ControllerPollingStationReport";
import ControllerConstituencyReport from "../feature-module/inventory/ControllerConstituencyReport";
import PastRecord from "../feature-module/inventory/PastRecords";
import AdminResultDisplay from "../feature-module/inventory/AdminResultDisplay";
import ControllerResultDisplay from "../feature-module/inventory/ControllerResultDisplay";


export const publicRoutes = [
  //controllers
  {
    id: 1,
    path: routes.controllerResult,
    name: "controller-results",
    element: <ControllerResult />,
    route: Route,
  },
  {
    id: 1,
    path: routes.teamManagment,
    name: "team-managment",
    element: <ProtectedRoutes Component={TeamManagment} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerRegions,
    name: "controller-region-managment",
    element: <ControllerRegion />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerDistrict,
    name: "controller-district-managment",
    element: <ControllerDistrict />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerConsistuency,
    name: "controller-consistuency-managment",
    element: <ControllerConsistuency />,
    route: Route,
  },
  {
    id: 1,
    path: routes.resultDetails,
    name: "result-details",
    element: <ResultDetails />,
    route: Route,
  },
  {
    id: 1,
    path: routes.pollingstationReports,
    name: "pollingstationReports",
    element: <Reports />,
    route: Route,
  },
  {
    id: 1,
    path: routes.constituencyReport,
    name: "constituency-report",
    element: <ConstituencyReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.resultData,
    name: "result-data",
    element: <ResultDisplay />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerPollingStations,
    name: "result-details",
    element: <ControllerPollingStation />,
    route: Route,
  },
  {
    id: 1,
    path: routes.consistuencySelection,
    name: "consistuency-selection",
    element: <ConstituencySelection />,
    route: Route,
  },
  {
    id: 1,
    path: routes.agentPollingStations,
    name: "agent-pollingstations",
    element: <AgentPollingStations />,
    route: Route,
  },
  {
    id: 1,
    path: routes.agentDashboard,
    name: "agent-dashboard",
    element: <AgentDashboard />,
    route: Route,
  },
  {
    id: 1,
    path: routes.districtReport,
    name: "district-report",
    element: <DistrictReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerResultDisplay,
    name: "controller-result-display",
    element: <ControllerResultDisplay />,
    route: Route,
  },
  {
    id: 1,
    path: routes.nationReport,
    name: "national-report",
    element: <NationalReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.regionReport,
    name: "region-report",
    element: <RegionReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminPollingStationReports,
    name: "admin-pollingstation-reports",
    element: <AdminPollingStationReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminConstituencyReport,
    name: "admin-constituency-report",
    element: <AdminConstituencyReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminDistrictReport,
    name: "admin-district-report",
    element: <AdminDistrictReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminRegionReport,
    name: "admin-region-report",
    element: <AdminRegionReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminNationalReport,
    name: "admin-national-report",
    element: <AdminNationalReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerPollingStationReport,
    name: "controller-pollingstation-report",
    element: <ControllerPollingStationReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerConsistuencyReport,
    name: "controller-constituency-report",
    element: <ControllerConstituencyReport />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerDashboard,
    name: "controller-dashboard",
    element: <ControllerDashboard />,
    route: Route,
  },
  {
    id: 1,
    path: routes.agentResult,
    name: "agent-result",
    element: <AgentResult />,
    route: Route,
  },




  {
    id: 1,
    path: routes.otpManagment,
    name: "otp-managment",
    element: <OtpManagment />,
    route: Route,
  },
  {
    id: 1,
    path: routes.dashboard,
    name: "home",
    element: <ProtectedRoutes Component={Dashboard} requiredPermission="dashboard" />,
    route: Route,
  },

  {
    id: 1,
    path: routes.adminManagment,
    name: "admin-managment",
    element: <ProtectedRoutes Component={AdminManagment} requiredPermission="admin_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerManagment,
    name: "controller-managment",
    element: <ControllerManagment />,
    route: Route,
  },
  {
    id: 1,
    path: routes.pastRecords,
    name: "past-records",
    element: <PastRecord />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminResultDisplay,
    name: "admin-result-display",
    element: <AdminResultDisplay />,
    route: Route,
  },

  {
    id: 1,
    path: routes.addController,
    name: "add-controller",
    element: <AddController />,
    route: Route,
  },
  {
    id: 1,
    path: routes.partyManagment,
    name: "party-managment",
    element: <PartyManagement />,
    route: Route,
  },
  {
    id: 1,
    path: routes.agentManagment,
    name: "agent-managment",
    element: <ProtectedRoutes Component={AgentManagment} requiredPermission="agent_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminControllerManagment,
    name: "admin-controller-managment",
    element: <ProtectedRoutes Component={AdminController} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminParty,
    name: "admin-party-managment",
    element: <ProtectedRoutes Component={AdminParty} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminCandidate,
    name: "admin-candidate-managment",
    element: <ProtectedRoutes Component={AdminCandidate} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.editController,
    name: "edit-controller",
    element: <ProtectedRoutes Component={EditController} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminAgentManagment,
    name: "admin-agent-managment",
    element: <ProtectedRoutes Component={AdminAgent} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.userManagment,
    name: "user-managment",
    element: <ProtectedRoutes Component={UserManagment} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.records,
    name: "records",
    element: <ProtectedRoutes Component={Record} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.controllerAgents,
    name: "controller-agents",
    element: <ProtectedRoutes Component={ControllerAgent} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.adminDashboard,
    name: "admin-dashboard",
    element: <ProtectedRoutes Component={AdminDashboard} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.newsManagment,
    name: "news-managment",
    element: <ProtectedRoutes Component={News} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.sessions,
    name: "sessions",
    element: <ProtectedRoutes Component={Session} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.pollingManagment,
    name: "polling-managment",
    element: <ProtectedRoutes Component={PollingManagment} requiredPermission="polling_stations" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.regionManagment,
    name: "region-managment",
    element: <ProtectedRoutes Component={RegionManagment} requiredPermission="region_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.disrictManagment,
    name: "district-managment",
    element: <ProtectedRoutes Component={DistrictManagement} requiredPermission="district_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.systemSettings,
    name: "system-settings",
    element: <ProtectedRoutes Component={SystemSetting} requiredPermission="settings" />,
    route: Route,
  },

  {
    id: 1,
    path: routes.userDetails,
    name: "user-details",
    element: <ProtectedRoutes Component={UserDetails} requiredPermission="user_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.reports,
    name: "reports",
    element: <ProtectedRoutes Component={Reports} requiredPermission="reports" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.results,
    name: "results",
    element: <ProtectedRoutes Component={Results} requiredPermission="results" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.consistuencyManagment,
    name: "consistuency-managment",
    element: <ProtectedRoutes Component={ConsistuencyManagment} requiredPermission="consistuency_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.ballot,
    name: "ballot-managment",
    element: <ProtectedRoutes Component={Ballot} requiredPermission="eballot_managment" />,
    route: Route,
  },
  {
    id: 1,
    path: routes.designations,
    name: "designations",
    element: <ProtectedRoutes Component={Levels} />,
    route: Route,
  },
  {
    id: 1,
    path: routes.candidateManagment,
    name: "candidte-managment",
    element: <ProtectedRoutes Component={CandidateManagement} requiredPermission="candidate_managment" />,
    route: Route,
  },



  {
    id: 116,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 117,
    path: '/',
    name: 'Root',
    element: <Navigate to="/signin" />,
    route: Route,
  },

];
export const posRoutes = [

];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },

  {
    id: 7,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },


  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },
  {
    id: 10,
    path: routes.resetpasswordTwo,
    name: "resetpasswordTwo",
    element: <ResetpasswordTwo />,
    route: Route,
  },


];
